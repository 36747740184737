{
  "locale.en": "Englisch",
  "locale.de": "Deutsch",

  "common.add": "Hinzufügen",
  "common.cancel": "Abbrechen",
  "common.download": "Herunterladen",
  "common.loading": "Laden…",
  "common.manage": "Verwalten",
  "common.optional": "optional",
  "common.reload": "Bitte lade die Seite neu.",
  "common.remove": "Entfernen",
  "common.retry": "Wiederholen",
  "common.share": "Teilen",
  "common.unexpectedError": "Unerwarteter Fehler: {0}",

  "admin.title": "Admin",
  "admin.serverInfo.title": "Server.Info",
  "admin.serverInfo.description": "Die Hub-ID dient zur Identifizierung deiner Cryptomator Hub-Instanz für deine Lizenz. Für den Support gib bitte immer die angezeigte Hub- und Keycloak-Version an.",
  "admin.serverInfo.hubId.title": "Hub-ID",
  "admin.serverInfo.hubVersion.title": "Hub-Version",
  "admin.serverInfo.hubVersion.description.upToDate": "Hub ist aktuell.",
  "admin.serverInfo.hubVersion.description.updateExists": "Update auf Version {0} möglich.",
  "admin.serverInfo.hubVersion.description.fetchingUpdatesFailed": "Suche nach Updates ist fehlgeschlagen. Bitte prüfe manuell.",
  "admin.serverInfo.keycloakVersion.title": "Keycloak-Version",
  "admin.serverInfo.keycloakVersion.description": "Keycloak verwalten",
  "admin.licenseInfo.title": "Lizenz-Infos",
  "admin.licenseInfo.description": "Überprüfe deine Lizenzinformationen. Wenn du Änderungen vornehmen oder deinen Abonnementstatus überprüfen möchtest, drücke „Abonnement verwalten“.",
  "admin.licenseInfo.email.title": "Lizenziert für",
  "admin.licenseInfo.seats.title": "Anzahl der Sitze",
  "admin.licenseInfo.seats.description.enoughSeats": "Du hast {0} unbenutzte Sitze übrig.",
  "admin.licenseInfo.seats.description.zeroSeats": "Du hast keine Sitze mehr frei. Upgrade, wenn nötig.",
  "admin.licenseInfo.seats.description.undercutSeats": "Du hast mehr Plätze belegt, als du zur Verfügung hast. Entferne ggf. Benutzer oder führe ein Upgrade durch.",
  "admin.licenseInfo.issuedAt.title": "Ausgestellt am",
  "admin.licenseInfo.expiresAt.title": "Läuft ab am",
  "admin.licenseInfo.expiresAt.description.valid": "Deine Lizenz ist gültig.",
  "admin.licenseInfo.expiresAt.description.expired": "Deine Lizenz ist abgelaufen.",
  "admin.licenseInfo.manageSubscription": "Abonnement verwalten",
  "admin.licenseInfo.communityLicense.description": "Vielen Dank, dass du Cryptomator Hub nutzt! Du hast die Community-Lizenz erhalten. Wenn du mehr Sitze benötigst, erweiter deine Lizenz.",
  "admin.licenseInfo.communityLicense.type.title": "Typ",
  "admin.licenseInfo.communityLicense.upgradeLicense": "Lizenz erhalten",

  "authenticateVaultAdminDialog.title": "Tresor verwalten",
  "authenticateVaultAdminDialog.description": "Gib das Vault-Admin-Passwort des Tresors ein, um ihn zu verwalten.",
  "authenticateVaultAdminDialog.password": "Vault-Admin-Passwort",
  "authenticateVaultAdminDialog.error.formValidationFailed": "Passwort darf nicht leer sein",
  "authenticateVaultAdminDialog.error.wrongPassword": "Falsches Passwort",

  "fetchError.title": "Abruf von Daten fehlgeschlagen",

  "createVault.title": "Tresor erstellen",
  "createVault.description": "Das Vault-Admin-Passwort sollte geheim gehalten werden und wird nur für administrative Zwecke benötigt. Bitte achte darauf, dass du dir das Passwort merkst.",
  "createVault.vaultName": "Tresorname",
  "createVault.vaultDescription": "Beschreibung",
  "createVault.password": "Vault-Admin-Passwort",
  "createVault.password.description": "Muss mindestens 8 Zeichen enthalten.",
  "createVault.passwordConfirmation": "Vault-Admin-Passwort bestätigen",
  "createVault.passwordConfirmation.passwordsMatch": "Passwörter stimmen überein!",
  "createVault.passwordConfirmation.passwordsDoNotMatch": "Passwörter stimmen nicht überein",
  "createVault.submit": "Tresor erstellen",
  "createVault.error.formValidationFailed": "Bitte überprüfe das Formular und versuche es erneut.",
  "createVault.error.vaultAlreadyExists": "Ein Tresor mit dem angegebenen Namen existiert bereits.",
  "createVault.error.downloadTemplateFailed": "Download der Tresorvorlage fehlgeschlagen: {0}",
  "createVault.success.title": "Tresor erstellt",
  "createVault.success.description": "Nachdem du den gezippten Tresorordner heruntergeladen hast, entpacke ihn an einem beliebigen Ort, der für deine Teammitglieder freigegeben ist.",
  "createVault.success.download": "Gezippten Tresorordner herunterladen",
  "createVault.success.return": "Zurück zur Tresorliste",

  "deviceList.empty.message": "Keine Geräte",
  "deviceList.empty.description": "Um ein Gerät hinzuzufügen, füge einen Tresor aus diesem Hub zur Cryptomator-App des Geräts hinzu und entsperre es.",
  "deviceList.title": "Geräte ({0})",
  "deviceList.deviceName": "Gerätename",
  "deviceList.type": "Typ",
  "deviceList.added": "Hinzugefügt",

  "downloadVaultTemplateDialog.title": "Tresorvorlage herunterladen",
  "downloadVaultTemplateDialog.description": "Lade die gezippte Tresorvorlage herunter und entpacke ihn an einem beliebigen Ort, der für deine Teammitglieder freigegeben ist. Dies ist einmal für die Ersteinrichtung erforderlich.",

  "grantPermissionDialog.title": "Berechtigung erteilen",
  "grantPermissionDialog.description": "Berechtige weitere Geräten auf den Tresor zuzugreifen.",
  "grantPermissionDialog.submit": "Berechtigung für {0} Gerät(e) gewähren",

  "nav.vaults": "Tresore",
  "nav.profile.signedInAs": "Eingeloggt als",
  "nav.profile.devices": "Deine Geräte",
  "nav.profile.settings": "Einstellungen",
  "nav.profile.admin": "Admin",
  "nav.profile.signOut": "Abmelden",
  "nav.mobileMenu": "Hauptmenü öffnen",

  "userSettings.title": "Einstellungen",
  "userSettings.general.title": "Allgemein",
  "userSettings.general.language.title": "Sprache",
  "userSettings.serverInfo.title": "Server-Info",
  "userSettings.serverInfo.description": "Für den Support gib bitte immer die angezeigte Hub- und Keycloak-Version an.",
  "userSettings.serverInfo.hubVersion.title": "Hub-Version",
  "userSettings.serverInfo.keycloakVersion.title": "Keycloak-Version",

  "vaultDetails.manageVault": "Tresor verwalten",
  "vaultDetails.description.header": "Beschreibung",
  "vaultDetails.description.empty": "Keine Beschreibung vorhanden.",
  "vaultDetails.information.header": "Information",
  "vaultDetails.information.created": "Erstellt",
  "vaultDetails.sharedWith.title": "Geteilt mit",
  "vaultDetails.updatePermissions": "Berechtigungen aktualisieren",
  "vaultDetails.downloadVaultTemplate": "Tresor-Vorlage herunterladen",

  "vaultList.title": "Tresore",
  "vaultList.empty.title": "Keine Tresore",
  "vaultList.empty.description": "Beginne mit der Erstellung eines neuen Tresors.",
  "vaultList.createVault": "Erstellen",
  "vaultList.filter": "Filter",
  "vaultList.filter.entry.allVaults": "Alle",
  "vaultList.filter.entry.accessibleVaults": "Zugreifbar",
  "vaultList.filter.result.empty.title": "Keine Tresore",
  "vaultList.filter.result.empty.description.admin": "Keinen Treffer mit diesem Suchbegriff oder filter.",
  "vaultList.filter.result.empty.description.user": "Keinen Treffer mit diesem Suchbegriff.",
  "vaultList.search.placeholder": "Suche..."
}
